import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { ReaderEnum, ReaderName, ReadersIcons } from 'core/helpers/ingest';
import { UserProfile } from 'apis/Profile';
import { DEFAULT_LIMIT, SecretKeyEnum } from 'core/constants';
import { Ingest } from 'apis/Ingest';
import { setUploadingIngest } from 'store/reducers/localSlice';

import CustomModal from 'components/Modal/CustomModal';
import IngestText from './ReaderForm/IngestText';
import IngestFile from './ReaderForm/IngestFile';
import IngestGoogleDriveFolder from './ReaderForm/IngestGoogleDriveFolder';
import IngestGoogleDocs from './ReaderForm/IngestGoogleDocs';
import IngestNotion from './ReaderForm/IngestNotion';
import ModalRenderForm from './ReaderForm/ModalRenderForm';
import IngestOneDrive from './ReaderForm/IngestOneDrive';
import IngestSharePoint from './ReaderForm/IngestSharePoint';
import IngestBox from './ReaderForm/IngestBox';
import IngestConfluence from './ReaderForm/IngestConfluence';
import IngestUrl from './ReaderForm/IngestUrl';

const IngestOptions = [
  {
    value: ReaderEnum.TEXT,
    name: ReaderName[ReaderEnum.TEXT],
    icon: ReadersIcons[ReaderEnum.TEXT],
    component: IngestText
  },
  {
    value: ReaderEnum.FILE,
    name: ReaderName[ReaderEnum.FILE],
    icon: ReadersIcons[ReaderEnum.FILE],
    component: IngestFile
  },
  {
    value: ReaderEnum.URL,
    name: ReaderName[ReaderEnum.URL],
    icon: ReadersIcons[ReaderEnum.URL],
    component: IngestUrl
  },
  {
    value: ReaderEnum.GOOGLE_DRIVE,
    name: ReaderName[ReaderEnum.GOOGLE_DRIVE],
    icon: ReadersIcons[ReaderEnum.GOOGLE_DRIVE],
    component: IngestGoogleDriveFolder
  },
  {
    value: ReaderEnum.GOOGLE_DOCS,
    name: ReaderName[ReaderEnum.GOOGLE_DOCS],
    icon: ReadersIcons[ReaderEnum.GOOGLE_DOCS],
    component: IngestGoogleDocs
  },
  // {
  //   value: ReaderEnum.SLACK,
  //   name: ReaderName[ReaderEnum.SLACK],
  //   icon: ReadersIcons[ReaderEnum.SLACK],
  //   component: IngestSlack,
  //   secretKeyType: SecretKeyEnum.SLACK
  // },
  // {
  //   value: ReaderEnum.GITHUB_REPOSITORY,
  //   name: ReaderName[ReaderEnum.GITHUB_REPOSITORY],
  //   icon: '/agent/icons_file.svg',
  //   component: IngestGithubRepository,
  //   secretKeyType: SecretKeyEnum.GITHUB
  // },
  // {
  //   value: ReaderEnum.GITHUB_ISSUE,
  //   name: ReaderName[ReaderEnum.GITHUB_ISSUE],
  //   icon: '/agent/icons_file.svg',
  //   component: IngestGithubIssue,
  //   secretKeyType: SecretKeyEnum.GITHUB
  // },
  {
    value: ReaderEnum.NOTION_PAGE,
    name: ReaderName[ReaderEnum.NOTION_PAGE],
    icon: ReadersIcons[ReaderEnum.NOTION_PAGE],
    component: IngestNotion,
    secretKeyType: SecretKeyEnum.NOTION
  },
  {
    value: ReaderEnum.MICROSOFT_ONE_DRIVE,
    name: ReaderName[ReaderEnum.MICROSOFT_ONE_DRIVE],
    icon: ReadersIcons[ReaderEnum.MICROSOFT_ONE_DRIVE],
    component: IngestOneDrive,
    secretKeyType: SecretKeyEnum.MICROSOFT_ONE_DRIVE
  },
  {
    value: ReaderEnum.MICROSOFT_SHARE_POINT,
    name: ReaderName[ReaderEnum.MICROSOFT_SHARE_POINT],
    icon: ReadersIcons[ReaderEnum.MICROSOFT_SHARE_POINT],
    component: IngestSharePoint,
    secretKeyType: SecretKeyEnum.MICROSOFT_SHARE_POINT
  },
  {
    value: ReaderEnum.BOX,
    name: ReaderName[ReaderEnum.BOX],
    icon: ReadersIcons[ReaderEnum.BOX],
    component: IngestBox,
    secretKeyType: SecretKeyEnum.BOX
  },
  // {
  //   value: ReaderEnum.YOUTUBE,
  //   name: ReaderName[ReaderEnum.YOUTUBE],
  //   icon: ReadersIcons[ReaderEnum.YOUTUBE],
  //   component: IngestYouTube,
  //   secretKeyType: SecretKeyEnum.YOUTUBE
  // },
  // {
  //   value: ReaderEnum.READWISE,
  //   name: ReaderName[ReaderEnum.READWISE],
  //   icon: '/ingest/readwise-icon.svg',
  //   component: IngestReadwise,
  //   secretKeyType: SecretKeyEnum.READWISE
  // },
  // {
  //   value: ReaderEnum.TELEGRAM,
  //   name: ReaderName[ReaderEnum.TELEGRAM],
  //   icon: '/ingest/telegram-icon.svg',
  //   component: IngestTelegram,
  //   secretKeyType: SecretKeyEnum.TELEGRAM
  // },
  {
    value: ReaderEnum.CONFLUENCE,
    name: ReaderName[ReaderEnum.CONFLUENCE],
    icon: ReadersIcons[ReaderEnum.CONFLUENCE],
    component: IngestConfluence,
    secretKeyType: SecretKeyEnum.CONFLUENCE
  }
];

const customReaderAPITypes = [ReaderEnum.FILE];

export default function IngestModal({ isOpen, onClose }: any) {
  const { projectUid } = useParams();
  const dispatch = useDispatch();

  const [readerType, setReaderType] = useState<any>();
  const [secretKeys, setSecretKeys] = useState<any[]>([]);

  const loadSecretKeys = async (readerOption: any) => {
    const secretKeyType = readerOption.secretKeyType;
    if (!secretKeyType) return null;

    const data = await UserProfile.getUserSecrets({
      key_type: secretKeyType,
      limit: DEFAULT_LIMIT
    });
    const keys = data?.results || [];
    setSecretKeys(keys);
  };

  useEffect(() => {
    if (readerType) {
      const readerOption = IngestOptions.find((obj) => obj.value == readerType);
      if (!readerOption) return;

      loadSecretKeys(readerOption);
    }
  }, [readerType]);

  const handleOnSubmit = async (payload: any) => {
    dispatch(setUploadingIngest({ isUploadingIngest: true }));

    const data = await Ingest.upload(projectUid, payload);

    dispatch(setUploadingIngest({ isUploadingIngest: false }));

    if (data !== null) {
      setReaderType(null);
      onClose(true);
    }
  };

  const renderIngestForm = () => {
    if (!readerType) return null;

    const readerOption = IngestOptions.find((obj) => obj.value == readerType);

    if (!readerOption) {
      return null;
    }

    const SelectedComponent = readerOption.component;

    if (customReaderAPITypes.includes(readerType)) {
      return (
        <SelectedComponent
          setReaderType={setReaderType}
          onClose={onClose}
          secretKeys={secretKeys}
        />
      );
    }
    return (
      <SelectedComponent
        handleOnSubmit={handleOnSubmit}
        onClose={onClose}
        secretKeys={secretKeys}
      />
    );
  };

  return (
    <CustomModal title="Select Knowledge Base" isOpen={isOpen} onClose={onClose}>
      <div className="mb-6 select-model">
        <div className="flex gap-6 flex-wrap">
          {IngestOptions.map((model) => (
            <div
              key={model.value}
              onClick={() => {
                setReaderType(model.value);
              }}
              className="border-[1px] border-[#252D38] bg-[#191F28] w-[161px] h-[110px] flex items-center flex-start p-6 rounded-lg cursor-pointer"
            >
              <div>
                <div className="mb-4">
                  <img width="20" src={`${model.icon}`} alt="Icon" />
                </div>
                {model.name}
              </div>
            </div>
          ))}
        </div>
        <ModalRenderForm isOpen={readerType} onClose={() => setReaderType(false)}>
          {renderIngestForm()}
        </ModalRenderForm>
      </div>
    </CustomModal>
  );
}
