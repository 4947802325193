export const AZURE_BLOB_HOST = process.env.REACT_APP_AZURE_BLOB_HOST;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
export const GOOGLE_SERVICE_EMAIL = process.env.REACT_APP_GOOGLE_SERVICE_EMAIL || '';
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL || '';
export const WS_URL = process.env.REACT_APP_WS_URL;
export const CHAT_WIDGET_SCRIPT_URL = process.env.REACT_APP_CHAT_WIDGET_SCRIPT_URL;

export const DEFAULT_LIMIT = 100;
export const CONVERSATION_LIMIT = 50;
export const MESSAGE_LIMIT = 50;
export const COLLABORATOR_LIMIT = 100;

export const DEFAULT_EXT = 'txt';

export const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

export enum DurationType {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
  LIFE_TIME = 'LIFE_TIME'
}

export enum PlanType {
  FREE = 'FREE',
  LIFE_TIME = 'LIFE_TIME',
  STANDARD = 'STANDARD',
  PREMIUM = 'PREMIUM',
  ENTERPRISE = 'ENTERPRISE'
}

export const LLM_MODELS = [
  { name: 'OpenAI Gpt-3.5 Turbo', value: 'openai_gpt3.5_turbo' },
  { name: 'OpenAI Gpt-4', value: 'openai_gpt4' },
  { name: 'OpenAI Gpt-4 Turbo', value: 'openai_gpt4_turbo' }
];
export const EMBEDDINGS = [
  { name: 'Openai 3 Small', value: 'openai_3_small' },
  { name: 'Openai 3 Large', value: 'openai_3_large' }
];

export enum AgentEnum {
  RAG = 'RAG',
  AGENT = 'AGENT'
}
export const AGENT_TYPES = [
  { name: 'Simple Agent', value: AgentEnum.RAG.toString() },
  { name: 'Advance Agent', value: AgentEnum.AGENT.toString() }
];

export enum SecretKeyEnum {
  OPENAI = 'OPENAI',
  MISTRAL = 'MISTRAL',
  COHERE = 'COHERE',
  GEMINI = 'GEMINI',
  ANTHROPIC = 'ANTHROPIC',
  GOOGLE = 'GOOGLE',
  SLACK = 'SLACK',
  GITHUB = 'GITHUB',
  NOTION = 'NOTION',
  MICROSOFT_ONE_DRIVE = 'MICROSOFT_ONE_DRIVE',
  MICROSOFT_SHARE_POINT = 'MICROSOFT_SHARE_POINT',
  BOX = 'BOX',
  YOUTUBE = 'YOUTUBE',
  READWISE = 'READWISE',
  TELEGRAM = 'TELEGRAM',
  CONFLUENCE = 'CONFLUENCE'
}
export const SecretKeyName = {
  [SecretKeyEnum.OPENAI]: 'Open AI',
  [SecretKeyEnum.MISTRAL]: 'MISTRAL',
  [SecretKeyEnum.COHERE]: 'COHERE',
  [SecretKeyEnum.GEMINI]: 'GEMINI',
  [SecretKeyEnum.ANTHROPIC]: 'ANTHROPIC',
  [SecretKeyEnum.CONFLUENCE]: 'CONFLUENCE',
  [SecretKeyEnum.TELEGRAM]: 'TELEGRAM',
  [SecretKeyEnum.GOOGLE]: 'Google',
  [SecretKeyEnum.SLACK]: 'Slack',
  [SecretKeyEnum.GITHUB]: 'Github',
  [SecretKeyEnum.NOTION]: 'Notion',
  [SecretKeyEnum.YOUTUBE]: 'Youtube',
  [SecretKeyEnum.BOX]: 'Box',
  [SecretKeyEnum.MICROSOFT_ONE_DRIVE]: 'Microsoft One Drive',
  [SecretKeyEnum.MICROSOFT_SHARE_POINT]: 'Microsoft Share Point'
};

export const SecretKeyIcons = {
  [SecretKeyEnum.OPENAI]: '/ingest/openai-icon.svg',
  [SecretKeyEnum.MISTRAL]: '/ingest/mistral-icon.png',
  [SecretKeyEnum.COHERE]: '/ingest/cohere-icon.png',
  [SecretKeyEnum.GEMINI]: '/ingest/gemini-icon.png',
  [SecretKeyEnum.ANTHROPIC]: '/public-brand-logo/anthropic.png',
  [SecretKeyEnum.CONFLUENCE]: '/public-brand-logo/confluence.svg',
  [SecretKeyEnum.TELEGRAM]: '/ingest/telegram-icon.svg',
  [SecretKeyEnum.YOUTUBE]: '/public-brand-logo/youtube.svg',
  [SecretKeyEnum.GOOGLE]: '/public-brand-logo/google.svg',
  [SecretKeyEnum.SLACK]: '/ingest/slack-icon.svg',
  [SecretKeyEnum.GITHUB]: '/ingest/github-icon.svg',
  [SecretKeyEnum.NOTION]: '/public-brand-logo/notion.svg',
  [SecretKeyEnum.BOX]: '/public-brand-logo/ms-box.svg',
  [SecretKeyEnum.MICROSOFT_ONE_DRIVE]: '/public-brand-logo/ms-onedrive.svg',
  [SecretKeyEnum.MICROSOFT_SHARE_POINT]: '/public-brand-logo/ms-sharepoint.svg'
};

export enum CustomerPlatform {
  MANYCHAT = 'MANYCHAT',
  WEB = 'WEB'
}

export enum ManyChatPlatformType {
  instagram = 'instagram',
  messenger = 'messenger',
  telegram = 'telegram',
  whatsapp = 'whatsapp'
}
