import React, { useEffect, useMemo, useState } from 'react';

import { PlanType, DurationType } from 'core/constants';
import { tokenToMessage, tokenToPage } from 'core/helpers/token';
import { Subscription } from 'apis/subscription';

import { SubscriptionCard } from '../SubscriptionCard/SubscriptionCard';

import './Subscription.css';

const PLAN_WISE_BENEFIT = {
  [PlanType.FREE]: [],
  [PlanType.LIFE_TIME]: [],
  [PlanType.STANDARD]: [
    'GPT-4O Mini and GPT-4O Model',
    'Multi-lingual Cohere And OpenAI Embedding Model'
  ],
  [PlanType.PREMIUM]: [
    'GPT-4O Mini, GPT-4O, (CLAUDE with personal Key)',
    'Special Embed Model for Law, finance, code'
  ],
  [PlanType.ENTERPRISE]: [
    'GPT-4O Mini, GPT-4O, CLAUDE',
    'Special Embed Model for Law, finance, code'
  ]
};

function getSubscriptionPlanBenefit(subscription: any) {
  return [
    `Create ${subscription.total_allowed_project} SimpleAgent`,
    `${tokenToPage(subscription?.per_project_allowed_ingest_token)} pages of knowledge per Agent`,
    `${tokenToMessage(subscription?.total_allowed_token)} Messages per month`,
    ...(PLAN_WISE_BENEFIT[subscription?.plan_type] || [])
  ];
}

export const Subscriptions = () => {
  const [isMonthly, setIsMonthly] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [userAllowedForFreeTrial, setUserAllowedForFreeTrial] = useState(false);

  const freeSubscription: any = useMemo(() => {
    return subscriptions.find((item: any) => item.plan_type === PlanType.FREE);
  }, [subscriptions]);

  const lifeTimeSubscription: any = useMemo(() => {
    return subscriptions.find((item: any) => item.plan_type === PlanType.LIFE_TIME);
  }, [subscriptions]);

  const monthlyPremiumSubscriptions = useMemo(() => {
    return subscriptions.filter(
      (item: any) => item.duration_type === DurationType.MONTHLY && item.plan_type !== PlanType.FREE
    );
  }, [subscriptions]);

  const yearlyPremiumSubscriptions = useMemo(() => {
    return subscriptions.filter(
      (item: any) => item.duration_type === DurationType.YEARLY && item.plan_type !== PlanType.FREE
    );
  }, [subscriptions]);

  const getSubscriptions = async () => {
    const data = await Subscription.getSubscriptions();
    if (data) {
      setSubscriptions(data.results);
      const is_user_allowed_for_free_trial = data.is_user_allowed_for_free_trial;
      setUserAllowedForFreeTrial(is_user_allowed_for_free_trial);
    }
  };

  useEffect(() => {
    getSubscriptions();
  }, []);

  const handleCheckboxChange = () => {
    setIsMonthly(!isMonthly);
  };

  const subscriptionList = isMonthly ? yearlyPremiumSubscriptions : monthlyPremiumSubscriptions;

  return (
    <div>
      <div className="flex justify-center">
        <h1 className="selectPackageText">Select a Package</h1>
      </div>

      <div className="flex justify-center mt-12">
        <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
          <input
            type="checkbox"
            name="autoSaver"
            className="sr-only"
            checked={isMonthly}
            onChange={handleCheckboxChange}
          />
          <span
            className="label flex items-center text-sm font-medium text-white"
            style={{ color: isMonthly ? '#8297AE' : 'white' }}
          >
            <span className="mr-4">Monthly </span>
          </span>
          <span
            className={`slider mr-3 flex h-[26px] w-[50px] items-center rounded-full p-1 duration-200`}
            style={{ border: '1px solid #5F6FFF' }}
          >
            <span
              style={{ backgroundColor: '#5F6FFF' }}
              className={`dot h-[18px] w-[18px] rounded-full bg-white duration-200 ${
                isMonthly ? 'translate-x-6' : ''
              }`}
            ></span>
          </span>
          <span
            className="label flex items-center text-sm font-medium text-white"
            style={{ color: isMonthly ? 'white' : '#8297AE' }}
          >
            <span className="pl-1">Yearly </span>
          </span>
        </label>
      </div>
      <div className="subscription-package-container">
        {freeSubscription && (
          <SubscriptionCard
            key={`subscriptions-${freeSubscription.id}`}
            id={freeSubscription.id}
            packageName={freeSubscription.name}
            description={freeSubscription.description}
            price={freeSubscription.price}
            plan_type={freeSubscription.plan_type}
            benefits={getSubscriptionPlanBenefit(freeSubscription)}
          />
        )}
        {lifeTimeSubscription && (
          <SubscriptionCard
            key={`subscriptions-${lifeTimeSubscription.id}`}
            id={lifeTimeSubscription.id}
            packageName={lifeTimeSubscription.name}
            description={lifeTimeSubscription.description}
            price={lifeTimeSubscription.price}
            plan_type={lifeTimeSubscription.plan_type}
            benefits={getSubscriptionPlanBenefit(lifeTimeSubscription)}
          />
        )}
        {subscriptionList.map((subscription: any) => {
          return (
            <SubscriptionCard
              key={`subscriptions-${subscription.id}`}
              id={subscription.id}
              packageName={subscription.name}
              description={subscription.description}
              price={subscription.price}
              plan_type={subscription.plan_type}
              free_trial_days={subscription.free_trial_days}
              benefits={getSubscriptionPlanBenefit(subscription)}
              userAllowedForFreeTrial={userAllowedForFreeTrial}
            />
          );
        })}
        {/* <SubscriptionCard packageName='Basic' description='Ideal for individual or trial use' price='Free' benefits={["aaaa"]} backgroundColor='rgba(255, 255, 255, 0.03);' />
          <SubscriptionCard packageName='Basic' description='Ideal for individual or trial use' price='Free' benefits={["aaaa"]} backgroundColor='rgba(18, 24, 33, 0.90)'/> */}
      </div>

      <p className="contact mt-8">
        Feel free to contact us for <span className="plan">Custom Plan</span>
      </p>
    </div>
  );
};
